<template>
  <!-- BEGIN: Content-->
  <div>
    <div class="offcanvas-body">
      <div class="row">
        <div :class="`col-lg-${user.roles.includes('super-admin') ? 6 : 12}`">
          <h3 class="cart-title">
            Content
          </h3>
          <div class="row">
            <div class="col">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Title</label>
                <input
                  v-model="alert.title"
                  class="form-control"
                  type="text"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Message</label>
                <quill-editor
                  v-model="alert.message"
                />
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-sm-4">
              <h5>Notification type</h5>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Select notification type</label>
                <v-select
                  v-model="alert.type"
                  label="label"
                  :options="[
                    {label: 'Task', value: 1},
                    {label: 'Notification', value: 2},
                  ]"
                  :get-option-key="option => option.value"
                />
              </div>
            </div>
            <div class="col-sm-8">
              <h5>Dates</h5>
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Event date & hour</label>
                    <date-picker
                      v-model="alert.begin_date"
                      format="D MMM Y HH:mm"
                      class="w-100"
                      type="datetime"
                      value-type="format"
                      :show-hour="true"
                      :show-minute="true"
                      :disabled-date="disabledRange"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div
                    v-if="alert.type && alert.type.value == 1"
                    class="mb-1"
                  >
                    <label
                      for=""
                      class="form-label"
                    >End date & hour (optional)</label>
                    <date-picker
                      v-model="alert.end_date"
                      format="D MMM Y HH:mm"
                      class="w-100"
                      type="datetime"
                      value-type="format"
                      :show-hour="true"
                      :show-minute="true"
                      :disabled-date="disabledRange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-sm-4">
              <h5>Notificated</h5>
              <div class="form-check form-check-success form-switch d-flex align-items-center mb-1">
                <input
                  id="sendemail"
                  v-model="alert.send_intranet"
                  type="checkbox"
                  value="View Archived"
                  class="form-check-input"
                  checked
                >
                <label
                  class="form-check-label ms-1"
                  for="publishContent"
                >Send alert by intranet</label>
              </div>
              <div class="form-check form-check-success form-switch d-flex align-items-center mb-1">
                <input
                  id="sendemail"
                  v-model="alert.send_mail"
                  type="checkbox"
                  value="View Archived"
                  class="form-check-input"
                  checked
                >
                <label
                  class="form-check-label ms-1"
                  for="publishContent"
                > Send alert by email</label>
              </div>
              <div class="form-check form-check-success form-switch d-flex align-items-center mb-1">
                <input
                  id="sendemail"
                  v-model="alert.show_calendar"
                  type="checkbox"
                  value="View Archived"
                  class="form-check-input"
                  checked
                >
                <label
                  class="form-check-label ms-1"
                  for="publishContent"
                > Show in calendar</label>
              </div>
            </div>
            <div class="col-sm-8">
              <h5>Reminders</h5>
              <table class="table table-striped table-hover mb-1">
                <thead>
                  <tr>
                    <th>Days before</th>
                    <th>Type</th>
                    <th>Text</th>
                    <th class="text-end">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="reminder, index in alert.reminders"
                    :key="index"
                  >
                    <td>{{ reminder.days }}</td>
                    <td>{{ reminder.type ? 'All' : 'No answer' }}</td>
                    <td>{{ reminder.text }}</td>
                    <td class="text-end">
                      <span
                        class="dis-ib"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete reminder"
                      ><a
                        class="btn btn-icon rounded-circle btn-flat-danger"
                        @click="alert.reminders.splice(index, 1)"
                      ><i data-feather="trash-2" /></a></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row d-flex jusi">
                <div class="col-sm-6">
                  <div class="mb-1">
                    <input
                      id="dateOpen"
                      v-model="reminder_days"
                      type="number"
                      min="1"
                      class="form-control"
                      placeholder="How many days..."
                    >
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-1">
                    <v-select
                      v-model="reminder_type"
                      label="label"
                      :options="[
                        {label: 'All', value: true},
                        {label: 'No answer', value: false},
                      ]"
                      :get-option-key="option => option.value"
                    />
                    <small class="text-muted">All (all receivers). <br>No answer (receivers not check the task)</small>
                  </div>
                </div>
                <div class="col-sm-12">
                  <textarea
                    v-model="reminder_text"
                    class="form-control"
                  />
                </div>
                <div class="col-sm-2">
                  <div class="my-2">
                    <a
                      class="btn btn-dark"
                      @click="addReminder"
                    >Add</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <hr>
          <h5>Attached file(s) (Total size: {{ totalSizeFiles }}MB)</h5>
          <vue-dropzone
            id="dropzone"
            ref="myVueDropzone"
            :options="dropzoneOptions"
            @vdropzone-file-added="handleFileAdded"
          />
        </div>
        <div
          v-if="user.roles.includes('super-admin')"
          class="col-lg-6"
        >
          <div class="bg-light-secondary px-2 py-2 br-md">
            <h3 class="cart-title">
              Send to: <span class="badge bg-light-success">{{ selectedUsers.length }}</span>
            </h3>
            <h5>Filter</h5>
            <div class="row d-flex align-items-end">
              <div class="col-sm-6">
                <label
                  for=""
                  class="form-label"
                >Host</label>
                <v-select
                  v-model="host"
                  label="custom_name"
                  :options="institutions.map(e => {
                    e.custom_name = e.code + ' - ' + e.name
                    return e
                  })"
                  :get-option-key="option => option.id"
                  @search="onSearch({ name: $event }, 'institutions/filter')"
                />
              </div>
              <div class="col-sm-6">
                <label
                  for=""
                  class="form-label"
                >Role</label>
                <v-select
                  v-model="role"
                  label="name"
                  :options="roles"
                  :get-option-key="option => option.id"
                />
              </div>
              <div :class="`col-sm-${type ? 4 : 6}`">
                <label
                  for=""
                  class="form-label"
                >Area</label>
                <v-select
                  v-model="area"
                  label="name"
                  :options="areas"
                  :get-option-key="option => option.id"
                  @search="onSearch({ name: $event }, 'areas/filter')"
                />
              </div>
              <div :class="`col-sm-${type ? 4 : 6}`">
                <label
                  for=""
                  class="form-label"
                >Call type</label>
                <v-select
                  v-model="type"
                  :options="[
                    'Peer',
                    'Evaluator',
                    'Applicant',
                    'Enter call',
                    'PCM',
                  ]"
                />
              </div>
              <div
                v-if="type"
                class="col-sm-4"
              >
                <label
                  for=""
                  class="form-label"
                >Call</label>
                <v-select
                  v-model="call"
                  label="title"
                  :options="convos"
                  :get-option-key="option => option.id"
                  @search="onSearch({ name: $event }, 'convo/filter')"
                />
              </div>
              <div class="col-sm-1 mt-1">
                <button
                  class="btn btn-dark"
                  @click="searchGroup"
                >
                  <template v-if="!searching">
                    Search
                  </template>
                  <div
                    v-else
                    class="loading custom-loading"
                  >
                    <div
                      class="effect-1 effects"
                      style="border-left: 3px solid white;"
                    />
                    <div
                      class="effect-2 effects"
                      style="border-left: 3px solid white;"
                    />
                    <div
                      class="effect-3 effects"
                      style="border-left: 3px solid white;"
                    />
                  </div>
                </button>
              </div>
            </div>
            <hr>
            <h5>Add user</h5>
            <div class="row">
              <div class="col-sm-12">
                <div class="mb-1">
                  <select
                    v-model="showUsers"
                    class="form-select select2"
                    :style="{'height': getActualHeight}"
                    multiple
                  >
                    <option
                      v-for="user, index in usersFound"
                      :key="index"
                      :value="user"
                    >
                      {{ user.true_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="mb-1">
                  <a
                    class="btn btn-dark"
                    @click="selectedUsers = selectedUsers.concat(showUsers.filter(item => !selectedUsers.includes(item))), replaceFeather()"
                  >
                    <template v-if="!searching">
                      Add
                    </template>
                    <template v-else>
                      <div
                        class="loading custom-loading"
                      >
                        <div
                          class="effect-1 effects"
                          style="border-left: 3px solid white;"
                        />
                        <div
                          class="effect-2 effects"
                          style="border-left: 3px solid white;"
                        />
                        <div
                          class="effect-3 effects"
                          style="border-left: 3px solid white;"
                        />
                      </div>
                    </template>
                  </a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-3">
                <div class="btn-group dropdown-icon-wrapper d-none d-sm-block disp-in mb-1">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <button
                        type="button"
                        class="btn btn-secondary dropdown-toggle dropdown-toggle-split btn-sm"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Add favourites lists
                      </button>
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="loadList('researchers')">
                        <i
                          class="me-50"
                          data-feather="users"
                        /> All researchers
                      </b-dropdown-item>
                      <b-dropdown-item @click="loadList('staff')">
                        <i
                          class="me-50"
                          data-feather="users"
                        /> All staff
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item
                        v-for="list in lists"
                        :key="list.id"
                        @click="loadList(list.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="list"
                        /> {{ list.name }}
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </div>
              <div class="col-4">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <button
                      type="button"
                      class="btn btn-secondary dropdown-toggle dropdown-toggle-split btn-sm"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Multiple actions
                    </button>
                  </template>
                  <div
                    class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                  >
                    <b-dropdown-item @click="deleteSelecteds">
                      <i
                        class="me-50"
                        data-feather="trash-2"
                      /> Delete selecteds
                    </b-dropdown-item>
                    <b-dropdown-item @click="saveList">
                      <i
                        class="me-50"
                        data-feather="save"
                      /> Save this list with a favourite
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </div>
            <hr>
            <h5>{{ selectedUsers.length }} receiver(s) will be notified</h5>
            <table class="table table-striped table-hover mb-1 mt-50">
              <thead>
                <tr>
                  <th class="px-w-checkbox-table">
                    <input
                      type="checkbox"
                      @click="selectAll($event.target.checked)"
                    >
                  </th>
                  <th>Name</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="user, index in selectedUsers"
                  :key="index"
                >
                  <td>
                    <input
                      :checked="selectedItems.findIndex(x => x.id === user.id) >= 0"
                      type="checkbox"
                      @click="select($event.target.checked, user)"
                    >
                  </td>
                  <td>{{ user.true_name }}</td>
                  <td class="text-end">
                    <span
                      class="dis-ib"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete"
                    ><a
                      class="btn btn-icon rounded-circle btn-flat-danger"
                      @click="selectedUsers.splice(index, 1)"
                    ><i data-feather="trash-2" /></a></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas-footer mt-auto">
      <div class="row">
        <div class="col-6">
          <button
            v-if="!sending"
            type="button"
            class="btn btn-dark mb-1 d-grid w-100"
            @click="submit(false)"
          >
            Create
          </button>
          <button
            v-if="sending"
            class="btn btn-dark mb-1 w-100"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Creating...</span>
          </button>
        </div>
        <div
          v-if="user.roles.includes('super-admin')"
          class="col-6"
        >
          <button
            v-if="!sending"
            type="button"
            class="btn btn-warning mb-1 d-grid w-100"
            @click="submit(true)"
          >
            Save draft
          </button>
          <button
            v-if="sending"
            class="btn btn-warning mb-1 w-100"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving as draft...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Vue from 'vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    vueDropzone: vue2Dropzone,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      alert: {
        reminders: [],
        files: [],
        send_mail: true,
        send_intranet: true,
        show_calendar: true,
        shared_with: [],
      },
      sending: false,
      dropzoneOptions: {
        url: '#',
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        autoProcessQueue: false,
      },
      reminder_type: null,
      reminder_days: null,
      reminder_text: null,
      usersFound: [],
      selectedUsers: [],
      showUsers: [],
      selectedItems: [],
      host: null,
      role: null,
      area: null,
      type: null,
      call: null,
      searching: false,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      user: 'auth/admin',
      roles: 'roles/allRoles',
      convos: 'convo/convos',
      institutions: 'institutions/institutions',
      areas: 'areas/areas',
      lists: 'alerts/lists',
      visible: 'modals/createAlert',
      alertEdit: 'alerts/alertEdit',
    }),
    totalSizeFiles() {
      return this.alert.files.reduce((total, file) => total + file.size, 0) / 1000000
    },
    getActualHeight() {
      let height = 200

      if (this.usersFound.length > 10) {
        height += ((this.usersFound.length - 10) * 20)
      }

      return `${height < 500 ? height : 500}px`
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.alert = this.alertEdit
        this.selectedUsers = this.alert.shared_with
        this.replaceFeather()
      } else {
        this.alert = {
          reminders: [],
          files: [],
          send_mail: true,
          send_intranet: true,
          show_calendar: true,
          shared_with: [],
        }
        this.$store.dispatch('alerts/saveAlertEdit', this.alert)
      }
    },
  },
  async mounted() {
    this.$store.dispatch('roles/fetchAll')
    this.$store.dispatch('institutions/filter', { has_active_researcher: true })
    this.$store.dispatch('areas/fetch')
    this.$store.dispatch('alerts/fetchLists')
    this.$store.dispatch('convo/fetchAll')
    this.replaceFeather()
  },
  methods: {
    disabledRange(date) {
      return date <= new Date()
    },
    async submit(draft = false) {
      this.sending = true
      if (this.user.roles.includes('super-admin')) {
        this.alert.users = this.selectedUsers.map(e => e.id)
      }

      this.alert.draft = draft

      await this.$store.dispatch('alerts/create', this.alert)
      this.$store.dispatch('modals/toggleCreateItem', false)
      if (this.alertEdit.title) {
        this.$store.dispatch('alerts/filterSendAlerts', this.filters)
      }
      this.alert = {
        reminders: [],
        files: [],
        send_mail: true,
        send_intranet: true,
        show_calendar: true,
      }
      this.sending = false
      this.$toastr.success('', 'New alert created')
    },
    replaceFeather() {
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async searchGroup() {
      this.searching = true
      if (this.host || this.role || this.area || (this.call && this.type)) {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/groups`, {
          host: this.host ? this.host.id : null,
          role: this.role ? this.role.id : null,
          area: this.area ? this.area.id : null,
          call: this.call ? this.call.id : null,
          type: this.type,
        })

        this.usersFound = resp.data.data

        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 300)
      } else {
        Vue.swal('Please, add some filters', '', 'warning')
      }
      this.searching = false
    },
    addReminder() {
      this.alert.reminders.push({
        type: this.reminder_type.value,
        days: this.reminder_days,
        text: this.reminder_text,
      })

      this.reminder_type = null
      this.reminder_days = null
      this.reminder_text = null
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    async onSearch(search, store) {
      await this.$store.dispatch(store, search)
    },
    select(click, user) {
      if (click) {
        this.selectedItems.push(user)
      } else {
        this.selectedItems.splice(this.selectedItems.findIndex(x => x.id === user.id), 1)
      }
    },
    selectAll(click) {
      if (click) {
        this.selectedItems = [...this.selectedUsers]
      } else {
        this.selectedItems = []
      }
    },
    deleteSelecteds() {
      const selectedIds = this.selectedItems.map(f => f.id)
      this.selectedUsers = this.selectedUsers.filter(e => !selectedIds.includes(e.id))
    },
    async loadList(type) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/alerts/load-list/${type}`)
      this.selectedUsers = resp.data
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    async saveList() {
      Vue.swal({
        title: 'List name',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        preConfirm: async name => {
          await this.$store.dispatch('alerts/saveList', {
            users: this.selectedItems.map(f => f.id),
            name,
          })
        },
      }).then(result => {
        if (result.isConfirmed) {
          Vue.swal('List created successfully', '', 'success')
        }
      })
    },
    handleFileAdded(file) {
      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        size: file.size,
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
      }

      this.alert.files.push(fileData)
      if (this.totalSizeFiles > 20) {
        this.$refs.myVueDropzone.dropzone.removeFile(file)
        this.alert.files.splice(-1, 1)
        this.$toastr.error('', 'The combination of file sizes exceeding 20MB!')
      }
    },
  },
}
</script>
